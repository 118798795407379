/* eslint-disable no-template-curly-in-string */
import { rsvpLocales as rsvp } from '../modules/rspv/locales/RsvpLocales';
import { esCampaigns } from '../modules/adtech/locales/es-campaigns';
import notFound from '../pages/locales/not-found/esNotFound';
import { errors } from './error-codes/esErrors';
import forbidden from '../pages/locales/forbidden/esForbidden';
import { esMap } from '../modules/tickets/locales';

const es = {
  global: {
    table: {
      dense: 'Vista comprimida',
      rows: 'Filas por página',
      noData: 'No hay datos que mostrar',
    },
    titles: {
      records: 'Historial',
      downloadReport: 'Descargar reporte',
    },
    messages: {
      success: 'Operación realizada con éxito.',
      error: 'Ocurrió un error en la operación, por favor intente más tarde.',
      action: '¿Está seguro de realizar esta acción?',
      downloadReport:
        'Obtén un listado detallado de los boletos registrados, incluyendo nombre, ID, correo electrónico y puntos de acceso.',
      noResults: 'No hay resultados',
    },
    buttons: {
      cancel: 'Cancelar',
      accept: 'Aceptar',
      delete: 'Borrar',
      save: 'Guardar',
      saveChanges: 'Guardar cambios',
      understood: 'Ok, enterado',
      finish: 'Finalizar',
      back: 'Anterior',
      next: 'Siguiente',
      records: 'Ver Historial',
      manualRegister: 'Registrar',
      edit: 'Editar',
      eliminate: 'Eliminar',
      register: 'Registrar',
      confirm: 'Confirmar',
      add: 'Agregar',
      select: 'Seleccionar',
      selectAll: 'Seleccionar todo',
      unselectAll: 'Dejar de seleccionar',
      search: 'Buscar',
      create: 'Crear',
      goBack: 'Regresar',
      remove: 'Quitar',
      continue: 'Continuar',
      showStaff: 'Ver Staff',
      gotIt: 'Entendido',
      seeMore: 'Ver más',
      seeLess: 'Ver menos',
      loadMore: 'Cargar más',
      activate: 'Activar',
      deactivate: 'Desactivar',
      close: 'Cerrar',
      ready: 'Listo',
      uploadAnother: 'Sube otro archivo',
      continueWithoutPromotion: 'Continuar sin MSI',
      disablePromotion: 'Desactivar promoción',
      viewDetails: 'Ver detalles',
    },
    placeholders: {
      search: 'Buscar',
      searchAll: 'Buscar por folio, nombre, correo, no. de reservación o ID',
      name: 'Nombre(s)',
      lastName: 'Apellido(s)',
      fullName: 'Nombre completo',
      email: 'Correo electrónico',
      password: 'Contraseña',
      cards: 'Tarjetas',
      buttons: 'Botones',
    },
    labels: {
      registered: 'Registrado',
      registered2: 'Registrados',
      notRegistered: 'Sin registro',
      noData: 'Sin datos',
      noDate: 'Sin fecha',
      event: 'Evento',
      events: 'Eventos',
      dates: 'Fechas',
      date: 'Fecha',
      time: 'Hora',
      venue: 'Lugar',
      venue2: 'Recinto',
      duplicate: 'Duplicado',
      active: 'Activo',
      inactive: 'Inactivo',
      archived: 'Archivado',
      deleted: 'Borrado',
      finished: 'Finalizado',
      label: 'Etiqueta',
      eventId: 'ID del evento',
      noLabel: 'Sin etiqueta',
      lastDay: 'Último día',
      removeTag: 'Quitar etiqueta',
      selected: 'Seleccionado',
      notSelected: 'No seleccionado',
      selectedAll: 'Seleccionados',
      notSelectedAll: 'No seleccionados',
      soldOut: '¡Vendido!',
      noTickets: 'No hay boletos registrados',
      new: 'Nuevo',
      site: 'Sitio',
      createdAt: 'Fecha de creación',
      status: 'Estado',
      ticket: 'Boleto',
      address: 'Dirección',
      settings: 'Ajustes',
      and: 'y',
      deactivated: 'Desactivado',
      undetermined: 'Sin determinar',
      banks: 'Bancos',
      promotion: 'Promoción',
      preSalePromotion: 'Promoción en Preventa',
      preSaleDuration: 'Duración de la Preventa',
      msiDuration: 'Duración de meses sin intereses',
      participatingBanks: 'Bancos participantes',
      offeredFees: '¿Cuántas cuotas deseas ofrecer?',
      duration: 'Duración',
      setting: 'Configuración',
      bank: 'Banco',
      quantity: 'Cantidad',
      updatedAt: 'Fecha de modificación',
      createdBy: 'Creado por',
      cards: 'Tarjetas',
      card: 'Tarjeta',
      cardName: 'Nombre de Tarjeta',
      addBank: 'Agregar banco',
      starts: 'Inicia',
      ends: 'Termina',
      msi: 'Meses sin intereses',
      tickets: 'Boletos',
      booking: 'Reservación',
      name: 'Nombre',
      notAvailable: 'No disponible',
      wallet: 'Acceso a boletos',
      sales: 'Ventas',
      billboards: 'Carteleras',
      billboard: 'Cartelera',
      noOptions: 'Sin opciones',
      not_published: 'No publicado',
    },
    validations: {
      requiredName: 'El nombre es requerido',
      requiredFirstName: 'Debes ingresar un nombre',
      requiredLastName: 'Debes ingresar un apellido',
      requiredEmail: 'Debes ingresar un correo electrónico',
      requiredURL: 'El URL es requerido',
      requiredPassword: 'Ingresa tu contraseña',
      requiredImage: 'La imagen es obligatoria',
      requiredDescription: 'La descripción es requerida',
      requiredDate: 'La fecha es requerida',
      validEmail: 'El correo electrónico debe ser válido',
      validDate:
        'La fecha es incorrecta, por favor ingresa la información con el siguiente formato: mm/dd/aaaa',
      validNumber: 'Debes ingresar un valor numérico',
      maxLengthDescription: 'La descripción no debe superar los 250 caracteres',
      maxStartDate:
        'La fecha debe ser anterior o igual a la del inicio del evento',
      maxFileSize:
        'El tamaño del archivo supera el límite de {{maxSize}} permitidos',
      fileInvalidType: 'El formato del archivo no es válido',
      bannerDimensions:
        'Las dimensiones de la imagen deben ser {{maxWidth}}px por {{maxHeight}}px',
      requiredOption: 'Debes seleccionar una opción',
      minNumber: 'El valor debe ser mayor o igual a ${{min}}',
      requiredBin: 'El BIN es requerido',
      requiredCardType: 'El tipo de tarjeta es requerido',
      invalidBinAmex: 'Un BIN de AMEX debe tener 5 caracteres',
      invalidBin: 'El BIN debe tener 6 caracteres',
      requiredCode: 'El código del banco es requerido',
      requiredCountry: 'El país del banco es requerido',
      requiredDateFormat:
        'La  fecha y hora deben tener el siguiente formato: dd/mm/aaaa hh:hh (a|p)m',
      requiredPreSaleStartDate:
        'La fecha de inicio debe ser posterior a la fecha final de msi en preventa {{date}}',
      requiredMSIEndDate:
        'La fecha final debe ser anterior a la fecha de inicio de MSI {{date}}',
      requiredMXNCurrency:
        'Para configurar los planes de pago a meses sin intereses, la moneda del evento debe ser MXN.',
      maxImageDimensions:
        'Las dimensiones máximas de la imagen son {{width}}x{{height}} px',
      unavailableName: 'Este nombre no está disponible',
    },
    contactInfo: {
      emails: {
        hola: 'hola@boletia.com',
      },
    },
  },
  login: {
    noAccount: '¿Aún no tienes cuenta? <signup>Regístrate</signup>',
    welcome: '¡Bienvenido de nuevo!',
    description: 'Inicia sesión para administrar tus eventos',
    emoji: '👋🏻',
    loginForm: {
      emailPlaceholder: 'Correo electrónico',
      passwordPlaceholder: 'Contraseña',
      validEmail: 'La dirección de correo electrónico debe ser válida',
      requiredEmail: 'Ingresa tu correo electrónico',
      requiredPassword: 'Ingresa tu contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      rememberMe: 'Recuérdame',
      login: 'Ingresar',
      noConfirmation: '¿No has recibido el correo de confirmación?',
      resendConfirmation: 'Da click <here>aquí</here> para volverlo a enviar.',
    },
    recaptcha: {
      message: 'Este sitio está protegido por reCAPTCHA y Google.',
      terms: '<privacy>Privacidad</privacy> y <terms>Condiciones</terms>.',
    },
  },
  signup: {
    appBar: {
      desktop:
        'Registro exclusivo para organizadores. <strong>¿Quieres comprar boletos?</strong> Regresa a <boletia>Boletia.com</boletia>',
      mobile:
        '<strong>¿Quieres comprar boletos?</strong> Regresa a <boletia>Boletia.com</boletia>',
    },
    dialog: {
      title: '¡Crea eventos y experiencias únicas!',
      subtitle:
        'Simplificamos el proceso de la venta de boletos con la plataforma más segura y fácil de usar.',
      links:
        '¿Quieres comprar boletos? <boletia>Regresa a Boletia</boletia> ¿No has recibido tus boletos? Envíanos un correo a <email>{{email}}</email>',
      button: 'Crear cuenta de organizador',
    },

    hasAccount: '¿Ya tienes cuenta? <login>Ingresa</login>',
    welcome: '¡Bienvenido a Boletia!',
    subtitle: 'Regístrate como organizador',
    description:
      'Accede a todas las funcionalidades que tenemos para la gestión de tu evento.',
    listFirstItem: 'Crea y publica eventos en menos de 10 minutos',
    listSecondItem:
      'Consulta estadísticas y monitorea tus transacciones y boletos vendidos',
    listThirdItem:
      'Personaliza tus eventos con fases, tipos de boletos, métodos de pago y más',
    acceptTerms:
      'Al registrarte, aceptas los <terms>Términos de servicio</terms> y la <privacy>Política de privacidad</privacy>.',
    signupForm: {
      namePlaceholder: 'Nombre(s)',
      lastNamePlaceholder: 'Apellido(s)',
      organizerTypePlaceholder: '¿Cuál de estas opciones te define mejor?',
      emailPlaceholder: 'Correo electrónico',
      passwordPlaceholder: 'Contraseña',
      validEmail: 'La dirección de correo electrónico debe ser válida',
      validPasswordLength: 'La contraseña debe tener al menos 8 caracteres',
      validPasswordCapital: 'Debe incluir una letra mayúscula',
      validPasswordNumber: 'Debe incluir un número',
      validPasswordSpecial: 'Debe incluir un carácter especial (!@#$%&*-_.).',
      requiredEmail: 'Ingresa tu correo electrónico',
      requiredPassword: 'Ingresa tu contraseña',
      requiredName: 'Ingresa tu(s) nombre(s)',
      requiredLastName: 'Ingresa tu(s) apellido(s)',
      requiredOrganizerType: 'Selecciona una opción',
      invalidOrganizerType1:
        'Esta sección es exclusiva para organizadores de eventos.',
      invalidOrganizerType2: '¿Quieres comprar boletos?',
      signup: 'Crea tu cuenta',
      resendConfirmation: {
        title: 'Gracias por tu registro',
        confirmEmail: 'Te hemos enviado un email de confirmación.',
        confirmEmailDescription: `Verifica tu dirección de correo electrónico para completar tu registro.`,
        noConfirmation: `¿No recibiste el correo?`,
        action:
          'Revisa tu bandeja de no deseados/spam o haz click <here>aquí</here> para volverlo a enviar.',
        success: 'Se ha reenviado la confirmación a tu correo.',
        error: 'Hubo un error, por favor vuelve a intentarlo.',
      },
      organizerType: {
        organizer: 'Organizador o empresario',
        assistant: 'Asistente',
        productor: 'Productor',
        venue: 'Dueño o administrador de recinto',
        manager: 'Manager, representante o booker',
        broker: 'Broker',
        artist: 'Artista o generador de contenido',
        other: 'Otro',
      },
    },
  },
  resetPassword: {
    title: '¿Olvidaste tu contraseña?',
    description:
      'Ingresa la dirección de correo electrónico asociado a tu cuenta para enviarte un mensaje con los pasos a seguir para restablecer tu contraseña.',
    emailPlaceholder: 'Correo electrónico',
    requiredEmail: 'Ingresa tu correo electrónico',
    validEmail: 'La dirección de correo electrónico debe ser válida',
    submitButton: 'Cambiar contraseña',
    goBack: 'Regresar',
    error: 'Hubo un error, por favor vuelve a intentarlo.',
    success: '¡La solicitud de cambio de contraseña se ha enviado con éxito!',
    successDescription:
      'Hemos enviado un link a tu cuenta de correo electrónico.',
    successDescription2:
      'Por favor revisa tu bandeja y sigue las instrucciones para cambiar tu contraseña.',
  },
  resetWeakPassword: {
    title: '¡Actualiza tu contraseña!',
    subtitle: 'Te llevará solo 1 min',
    description:
      'Para mantener la seguridad de tu cuenta, te pedimos que crees una nueva contraseña. Ingresa el correo electrónico asociado a tu cuenta Boletia para recibir un mensaje con los siguientes pasos.',
    emailPlaceholder: 'Correo electrónico',
    requiredEmail: 'Ingresa tu correo electrónico',
    validEmail: 'La dirección de correo electrónico debe ser válida',
    submitButton: 'Actualizar contraseña',
    goBack: 'Regresar',
    error: 'Hubo un error, por favor vuelve a intentarlo.',
    success:
      '¡La solicitud de actualización de contraseña se ha enviado con éxito!',
    successDescription:
      'Hemos enviado un link a tu cuenta de correo electrónico.',
    successDescription2:
      'Por favor revisa tu bandeja y sigue las instrucciones para cambiar tu contraseña.',
  },
  changePassword: {
    title: 'Cambia tu contraseña',
    subtitle: 'La contraseña de tu cuenta es temporal, es necesario cambiarla.',
  },
  admin: {
    menu: {
      events: 'Mis eventos',
      billboards: 'Carteleras',
      statistics: 'Estadísticas',
      team: 'Mi equipo',
      unarchive: 'Desarchivar eventos',
      search: 'Cambiar perfil',
      eventsControl: 'Control de eventos',
      manageAccounts: 'Administrar cuentas',
      setPassword: 'Establecer contraseña',
      deleteAccounts: 'Eliminar cuentas',
      changeOwnership: 'Cambio de propietario',
      wallet: 'Acceso a boletos',
      adTech: 'Mis campañas',
    },
    pages: {
      events: {
        alerts: {
          notPermission:
            'No tienes permisos para acceder al evento. Verifica con el administrador',
        },
        table: {
          date: 'Fecha',
          name: 'Evento',
          channelType: 'Tipo de evento',
          sales: 'Ventas',
          payments: 'Métodos de pago',
          status: 'Estado',
          unarchive: 'Desarchivar',
          report: 'Reporte final',
        },
        tabs: {
          all: 'Todos',
          active: 'Activos',
          inactive: 'Inactivos',
          finished: 'Finalizados',
          archived: 'Archivados',
          merged: 'Combinados',
        },
        breadcrumbs: {
          links: [{ name: 'Listado' }],
          action: 'Crear evento',
        },
        initialModal: {
          title: 'Eventos',
          subtitle: '¡Crea eventos y experiencias únicas!',
          description:
            'Gestiona y controla los eventos presenciales, online o live streaming que hayas creado.',
        },
        statistics: [
          'Eventos con boletos vendidos',
          'Boletos creados',
          'Bookings vendidos',
        ],
        empty: 'No hay eventos que mostrar',
        searchBy: 'Buscar evento por nombre, id o fecha (aaaa-mm-dd)',
      },
      myTeam: {
        initialModal: {
          title: 'Mi Equipo',
          subtitle: 'Gestiona tu equipo y optimiza tu operación',
          description:
            'Agrega integrantes a tu equipo y gestiona sus roles y permisos para que tengan acceso a ciertas partes de la administración de tus eventos.',
        },
        breadcrumbs: {
          heading: 'Mi Equipo',
          action: 'Agrega un integrante',
        },
        emptyState: { title: 'Aún no hay integrantes en tu equipo' },
        addForm: {
          success: 'Colaborador agregado exitosamente',
          error: 'Ocurrió un error al agregar al colaborador',
        },
        noEvents: {
          title: 'No tienes eventos aún',
          desc: 'Para poder agregar invitados a tu equipo necesitas tener al menos un evento creado',
          label: 'Crear evento',
        },
      },
      billboards: {
        actions: {
          delete: {
            action: '¿Estás seguro de eliminar esta cartelera?',
            success: 'La cartelera se ha eliminado exitosamente',
            error: 'Ocurrió un problema al eliminar esta cartelera',
          },
          changeStatus: {
            activate: '¿Estás seguro de activar la cartelera?',
            disabled: '¿Estás seguro de desactivar la cartelera?',
            success: 'El estado de la cartelera se ha cambiado',
            error: 'Ocurrió un problema al actualizar esta cartelera',
          },
        },
      },
      statistics: {
        filters: {
          byEvent: 'Por ID de evento',
          byUser: 'Por ID de usuario',
        },
        breadcrumbsInsiders: {
          insiders: 'Vendedores',
          byInsiderTitle: 'Filtrar por vendedor',
          placeholder: 'ID de usuario, nombre o correo electrónico',
          search: 'Buscar',
          insiderName: 'Nombre vendedor',
          salesChannel: 'Canal de venta',
          clear: 'Limpiar',
          filter: 'Filtrar',
        },
      },
      surveys: {
        actions: {
          submit: {
            warning:
              'Te falta agregar opciones a las preguntas del tipo seleccionar de una lista',
          },
        },
      },
      unarchive: {
        initialModal: {
          title: 'Desarchivar eventos',
          description:
            'Es una opción que tienes como super admin con la cual podrás apoyar a los organizadores que tengan eventos antiguos archivados a visualizarlos y poderlos editar nuevamente.',
        },
        success: 'El evento se ha desarchivado exitosamente',
        error: 'Ocurrió un error al desarchivar el evento',
      },
      search: {
        initialModal: {
          title: 'Cambiar perfil',
          description:
            'Busca usuarios y eventos por su ID o nombre para poder visualizarlos y editarlos.',
        },
      },
      changeOwnership: {
        initialModal: {
          title: 'Cambiar de propietario',
          description:
            'Busca eventos de un usuario para poder transferirlos a otro',
        },
      },
    },
  },
  dashboard: {
    pageTitles: {
      sales: 'Ventas',
      clients: 'Mis Clientes',
      funnel: 'Funnel de Ventas',
    },
    state: 'Estado',
    city: 'Ciudad',
    venue: 'Recinto',
    eventDate: 'Fecha del evento',
    left: 'Faltan',
    ended: 'Evento finalizado',
    days: 'días',
    noData: 'Sin info',
    netSales: 'Ventas Netas',
    serviceCharges: 'Cargos por Servicio',
    totalSales: 'Ventas Totales',
    noSales: 'Aún no tienes ventas',
    soldTickets: 'Boletos Vendidos',
    notEnoughData: 'No hay información suficiente para mostrar',
    sales: {
      topSales: 'Top 3 boletos más vendidos',
      sales: 'Ventas por tipo de boleto',
      target:
        'Tu <strong>objetivo</strong> de ventas es: <strong>{{ticketsTarget}} boletos</strong> o <strong>{{salesTarget}} MXN </strong>antes del {{dateTarget}}.',
      dateFormat: 'D [de] MMMM [de] YYYY',
      globalSales:
        'Tus ingresos netos pueden ser de {{sales}} vendiendo los {{tickets}} boletos que tienes en el inventario',
      confirmTarget: '¿Este es tu objetivo de ventas?',
      targetDescription:
        'Configura un objetivo y recibe recomendaciones personalizadas',
      setUpTarget: 'Configurar nuevo objetivo',
      keepTarget: 'Mantener objetivo',
      salesTarget:
        '¿Cuál es el ingreso neto que necesitas para llegar a tu meta?',
      newTarget: 'Nuevo objetivo',
      ticketsTarget:
        '¿Cuántos boletos necesitas vender para llegar a tu objetivo?',
      dateTarget: 'Fecha límite',
      targetInfo:
        'Este objetivo nos ayuda a ofrecerte recomendaciones para llegar a tu meta y no altera la cantidad de boletos que configuraste en el evento.',
      submitTarget: 'Configurar objetivo',
      netIncome: 'ingresos netos',
      soldTickets: 'boletos vendidos',
      goodNews: '¡Buenas noticias!',
      goodNewsDescription:
        'Recibirás recomendaciones personalizadas para ayudarte a alcanzar tu próximo objetivo:',
      goodNewsDescription2:
        'Vender {{tickets}} boletos o {{sales}} MXN antes del {{date}}.',
      goodNewsDescription3:
        'Estamos actualizando tus ajustes, regresa en unos minutos para verlos reflejados.',
      updateTargetError: 'Ocurrió un error al actualizar el objetivo',
    },
  },
  event: {
    holds: {
      actions: {
        delete: {
          action: '¿Estás seguro de liberar estos asientos?',
          body: 'Al liberar los asientos, dejarán de estar apartados en tu hold',
          description: 'Esto no afecta a tus cortesías generadas',
          success: 'Hold borrado exitosamente',
          error: 'Ocurrió un error al borrar este hold',
          links:
            'Este hold tiene ligas generadas, ¿deseas eliminarlas también?',
        },
        create: {
          name: 'Nombre del hold',
          namePlaceholder: 'p.ej: General, VIP, Paquete Full Beneficios',
          sublabel: 'Selecciona los asientos que deseas apartar en el mapa',
          save: 'Crear Hold',
          warning: 'Selecciona los asientos a apartar antes de continuar',
          success: 'Hold creado exitosamente',
          error: 'Ocurrió un error al crear este hold',
        },
        edit: {
          title: '¿Estás seguro de que deseas liberar este hold?',
          subtitle:
            'Al liberar todos los asientos, este hold se eliminará por completo.',
          success: 'Hold actualizado exitosamente',
          error: 'Ocurrió un error al actualizar este hold',
        },
      },
      info: {
        instructions: 'Instrucciones',
        one: '1.- Escribe el nombre de tu hold',
        two: '2.- Selecciona asientos que quieres holdear',
        editHold: 'Editar hold',
        editHoldSubtitle: 'Agrega o quita lugares de tu hold',
        editHoldDescription:
          'Puedes agregar o quitar lugares de tu hold para modificar la cantidad de cortesías que tienes disponibles.',
      },
      multiSections: 'Múltiples zonas',
      selectedSeats: 'asientos seleccionados',
      emptyTitle: 'Evento sin mapa seleccionado',
      emptyDescription: 'Seleccione un mapa para continuar',
      forbidden: 'No estás autorizado para realizar este cambio',
    },
    batches: {
      actions: {
        create: {
          success: 'Cortesía creada exitosamente',
          error: 'Ocurrió un error al crear la cortesía',
          loading: 'Creando cortesía...',
        },
      },
    },
  },
  createEvent: {
    steps: {
      info: 'Información',
      category: 'Categoría',
      date: 'Fecha',
      location: 'Lugar',
    },
    title: 'Crear evento',
    subtitle: 'Crea un evento y una experiencia única para tus asistentes',
    description:
      'Crea tus eventos presenciales, online o live streaming desde aquí.',
    placeholders: {
      name: 'Nombre del evento',
      nameSublabel:
        'Si es tu primera edición usa un nombre corto y reconocible',
      subdomain: 'Sitio de ventas',
      subdomainSublabel: 'Esta es la URL de tu evento',
      onsite: 'Presencial',
      neerme: 'Neerme',
      digital: 'Digital (otros)',
      title: '¿Dónde es tu evento?',
      description: 'Descripción',
      mapLabel: 'Ubicación en el mapa',
      place: 'ej: Auditorio Luis Elizondo',
      address: `Usa el siguiente formato: 'calle, colonia, ciudad'`,
      map: 'Se posiciona en la dirección que hayas ingresado. O puedes reubicarlo manualmente.',
    },
    successModal: {
      title: 'El evento ha sido creado con éxito',
      subtitle:
        'Tu evento ha sido creado. Puedes seguir configurándolo o ir al listado de tus eventos.',
      goBack: 'Volver a mis eventos',
      goConfig: 'Continuar a configuración',
    },
    errorModal: {
      title: 'Hubo un problema al crear el evento',
      subtitle: 'Puedes regresar a editar los detalles para corregir el error',
      confirmation: 'Editar',
    },
    schema: {
      subdomainUnavailable: 'Este URL no está disponible',
      requiredSubdomain: 'Debes seleccionar un sitio de ventas',
      minDuration: 'El evento debe tener una duración mínima de 15 minutos',
      requiredName: 'El nombre del evento es obligatorio',
      minAbout: 'La descripción debe tener mínimo 10 caracteres',
      requiredChannelType: 'Debes seleccionar un tipo de evento',
      requiredAbout: 'Ingresa una descripción para el evento',
      requiredStartDate:
        'La hora de inicio tiene que posterior a la hora actual',
      requiredDateFormat:
        'La fecha y hora son incorrectas, por favor ingresa la información con el siguiente formato: mm/dd/aaaa hh:hh (a|p)m',
      requiredEndDate: 'La fecha debe ser posterior a la de inicio',
      requiredTimeZone: 'La zona horaria es obligatoria',
      maxLengthSubdomain: 'La URL para tu sitio de venta es demasiado larga',
      maxLengthName: 'El nombre del evento es demasiado largo',
      requiredAddress: 'La dirección es obligatoria',
      requiredVenue: 'El recinto es obligatorio',
      requiredCity:
        'Especifica la dirección exacta o aproximada del recinto para continuar',
      requiredBanner: 'Debes agregar un banner para el evento',
    },
  },
  eventStats: {
    perZone: 'Detalle por zona',
    perTicket: 'Detalle por tipo de boleto',
    zone: 'Zona',
    ticket: 'Boleto',
    capacity: 'Capacidad',
    sold: 'Vendidos',
    batches: 'Cortesías',
    holds: 'Holdeados',
    available: 'Disponibles',
    netSales: 'Ventas netas',
    issued: 'Emitidos',
    netIncome: 'Ingresos netos',
  },
  tickets: {
    commission: {
      model: 'Modelo de cobro',
      max: 'Máx. de boletos por orden',
      commission: 'Cargo por Servicio',
      processor: 'Procesador de pagos',
      title:
        '¿Estás seguro de cambiar el modelo de cobro a <bold>{{model}}</bold>?',
      description:
        'Recuerda que una vez vendido el primer boleto, no se puede cambiar la tarifa elegida.',
      included: 'incluido',
      separated: 'separado',
      updating: 'Actualizando modelo de cobro...',
      success: 'Modelo de cobro actualizado exitosamente',
    },
    update: {
      success: 'Boleto actualizado exitosamente',
      error: 'Ocurrió un error al actualizar el boleto',
    },
    maxCapacity: {
      input: {
        title: 'Aforo total:',
        description:
          'Especifica el aforo total de tu evento para supervisar su disponibilidad y medir su rendimiento.',
      },
      editButton: 'Editar',
      cancelButton: 'Cancelar',
      saveButton: 'Guardar',
      left: 'Quedan ',
      places: 'lugares',
      ocupied: 'Aforo ocupado',
      assigned: 'Aforo asignado',
      success: 'Aforo actualizado exitosamente',
      error: 'Ocurrió un error al actualizar el aforo',
    },
  },
  superAdmin: {
    changeProfile: 'Cambiar perfil',
    noResults: 'No hay resultados',
    searching: 'Buscando...',
    nameOrId: 'nombre o ID del evento',
    emailOrId: 'email o ID del usuario',
    exit: 'Salir',
    managedBy:
      'Actualmente estás administrando el perfil de <bold>{{user}}.</bold>',
    byEvent: 'Por evento',
    byUser: 'Por usuario',
    deleteAccounts: {
      initialModal: {
        title: 'Eliminar cuentas',
        description:
          'Es una opción que tienes como super admin con la cual podrás apoyar a los organizadores que tengan cuentas de usuarios duplicadas o que ya no utilicen a eliminarlas.',
      },
      title: 'Eliminar cuentas',
      placeholder: 'Buscar usuario por ID o email',
      dialog: {
        title: 'Eliminar cuenta',
        description:
          'Proporciona un motivo para eliminar la cuenta de <strong>{{username}}</strong>',
        success: 'La cuenta {{id}} se ha eliminado exitosamente',
        error: 'Ocurrió un error al eliminar la cuenta {{id}} {{error}}',
        reason: 'Motivo',
        reasons: {
          customerRequest: 'Solicitud del usuario',
          duplicate: 'Cuenta duplicada',
          fraud: 'Reporte de fraude',
          other: 'Otro',
        },
        other: 'Otro motivo',
        placeholder: 'Ej. Cuenta de prueba',
      },
      schema: {
        requiredReason: 'Debes seleccionar un motivo',
        requiredOther: 'Debes especificar un motivo',
        maxOther: 'El motivo no debe superar los 63 caracteres',
      },
      noResults: 'No se encontraron resultados',
    },
    setPassword: {
      title: 'Establecer contraseña',
      description:
        'Aquí puedes establecer una contraseña nueva para cualquier usuario, solo necesitas llenar el siguiente formulario.',
      button: 'Establecer contraseña',
      username: 'Correo electrónico',
      usernamePlaceholder: 'Ingresa el correo electrónico del usuario',
      password: 'Contraseña nueva',
      passwordPlaceholder: 'Ingresa la contraseña nueva',
      permanent: 'Duración',
      radio: {
        temporary: 'Temporal',
        permanent: 'Permanente',
      },
      schema: {
        requiredUsername: 'Debes ingresar el correo electrónico del usuario',
        requiredPassword: 'Debes ingresar la contraseña nueva',
        requiredPermanent: 'Debes seleccionar una duración',
        requiredEmail: 'Debes ingresar un correo electrónico válido',
      },
      success: 'La contraseña se ha establecido exitosamente',
      error: 'Ocurrió un error al establecer la contraseña:',
    },
    bines: {
      title: 'Configuración de Bines',
      description: 'Ingresa bancos y tarjetas para generar ventas exclusivas',
      addBank: 'Agregar banco',
      addCard: 'Agregar tarjeta',
      msi: 'Eventos con MSI',
      preSale: 'Eventos con preventa',
      banksTablePlaceholder: 'Buscar por nombre de banco o Bin de 6 dígitos',
      cardsTablePlaceholder: 'Buscar por nombre de tarjeta o Bin de 6 dígitos',
      delete: '¿Estás seguro de eliminar el banco "{{name}}"?',
      noBankData: 'No hay bancos que mostrar',
      noCardData: 'No hay tarjetas que mostrar',
      byBank: 'Bines por banco',
      insertBank: {
        error: 'Ocurrió un error al agregar el banco',
        success: 'Banco con bines agregado exitosamente',
      },
      insertBin: {
        error: 'Ocurrió un error al agregar la(s) tarjeta(s)',
      },
      deleteBank: {
        error: 'Ocurrió un error al eliminar el banco',
        success: 'Banco eliminado exitosamente',
      },
      deleteCard: {
        error: 'Ocurrió un error al eliminar la tarjeta',
        success: 'Tarjeta eliminada exitosamente',
      },
      updateCard: {
        error: 'Ocurrió un error al actualizar la tarjeta',
        success: 'Tarjeta actualizada exitosamente',
      },
    },
    wallet: {
      description:
        'Elige la opción por dónde  deseas que los asistentes accedan a sus boletos.',
      description2:
        'Una vez que el evento tenga ventas, no sé podrá cambiar la opción elegida.',
      placeholder: 'Buscar evento por nombre o ID',
      noData: 'No se encontraron resultados',
      find: 'Buscar eventos',
      events: 'Eventos con Wallet',
      successWallet: {
        title: 'Has activado boletos en app en este evento',
        description:
          'A partir de ahora los boletos comprados de este evento serán visualizados desde la app del asistente. Si necesitas regresar al boleto pdf, podrá cambiarse antes de iniciar ventas.',
      },
      successPdf: {
        title: 'Has activado boletos en PDF en este evento',
        description:
          'A partir de ahora los boletos comprados de este evento serán descargados desde el correo registrado. Si necesitas regresar al boleto en app, podrá cambiarse antes de iniciar ventas.',
      },
      error: 'Ocurrió un error al cambiar el acceso a boletos',
    },
  },
  changeOwnership: {
    title: 'Cambio de propietario',
    forms: {
      id: {
        placeholder: 'Ingresar el id del usuario',
      },
      email: {
        placeholder: 'email',
        validations: {
          format: 'Formato de correo inválido',
          required: 'Debes ingresar un correo',
        },
      },
    },
    modal: {
      title: 'Eventos transferidos',
      subtitle: '¿Deseas transferir más eventos o ir a mis eventos?',
      confirmation: 'Ir a mis eventos',
      confirmationCancel: 'Transferir más eventos',
    },
    table: {
      head: {
        select: 'Seleccionar',
        id: 'ID',
        name: 'Nombre',
      },
      toolbarLabel: 'Buscar evento por nombre o id',
      rowsPerPage: 'Filas por página',
      dense: 'Vista comprimida',
    },
    userFound: 'Eventos de',
    userNotFound: 'No se encuentra el usuario con el id',
    transferEventsLabel: 'Tranferir eventos a:',
    transferEventsButton: 'Transferir eventos',
  },
  onboarding: {
    modalButtons: {
      next: 'Siguiente',
      back: 'Atrás',
      finish: 'Finalizar',
    },
  },
  billboards: {
    billboards: 'Carteleras',
    billboard: 'Cartelera',
    description: '¿Tu evento se repite en distintas locaciones o fechas?',
    description2:
      'Crea un micro-sitio de venta. Categoriza, gestiona y visualiza los eventos desde aquí.',
    create: 'Crear cartelera',
    urlNoEdit: 'La url no podrá ser cambiada después de crear la cartelera',
    urlNoEdit2: 'La url no puede ser cambiada una vez creada la cartelera',
    titleEdit: 'Editar cartelera de eventos',
    titleCreate: 'Ingresa los datos de tu cartelera',
    titleType: '¿Qué tipo de cartelera deseas crear?',
    goToSite: 'Ver sitio',
    venue: {
      title: 'Cartelera Recinto',
      description:
        'Varios eventos con diferentes categorías, fechas y horarios que suceden en un mismo recinto.',
    },
    tour: {
      title: 'Cartelera Tour',
      description:
        'Un evento que sucede en diferentes recintos, diferentes fechas y horarios.',
    },
    festival: {
      title: 'Cartelera Festival',
      description:
        'Varios eventos con diferentes categorías, fechas y horarios que suceden en diferentes recintos.',
    },
    titleSelect: 'Agrega eventos a tu cartelera',
    name: 'Nombre de la cartelera',
    url: 'URL de la cartelera',
    banner: 'Agrega una imagen de fondo',
    about: 'Descripción de la cartelera',
    seo: 'Optimiza y dale seguimiento a tu evento',
    optional: '(Opcional)',
    tagManager: 'Google Tag Manager',
    tagManagerPlaceholder: 'Introduce tu GTM. Ej. GTM-1234567',
    pixel: 'Meta Pixel ID',
    pixelPlaceholder: 'Introduce los dígitos del pixel ID. Ej. 123456789012345',
    maxLengthName: 'Nombre muy largo, no debe de superar los 63 caracteres',
    maxLengthSubdomain: 'URL muy larga, no debe de superar los 63 caracteres',
    subdomainAlreadyExists: 'Esta URL no está disponible',
    aboutDescription:
      'Describe tu cartelera en un máximo de 250 caracteres, esta aparecerá al momento de compartir en redes sociales',
    bannerDescription: 'La imagen no debe pesar más de {{maxSize}}',
    bannerDescription2: 'Los formatos aceptados son .jpg, .jpeg, .png y .gif',
    bannerDescription3:
      'Se recomiendan dimensiones de {{maxWidth}}px por {{maxHeight}}px',
    showSelected: 'Mostrar seleccionados',
    showAll: 'Mostrar todos',
    searchBy: 'Buscar evento por nombre o ID',
    dragLabel:
      'Arrastra una imagen hasta aquí o <br/><underline>Sube un archivo</underline>',
    dropLabel: 'Suelta tu archivo para agregarlo',
    creating: 'Creando cartelera',
    editing: 'Editando cartelera',
    delete: '¿Estás seguro de eliminar la cartelera "{{billboardName}}"?',
    deleteSuccess: 'La cartelera se ha eliminado exitosamente',
    deleteError: 'Ocurrió un problema al eliminar esta cartelera',
    edit: {
      activate: 'Activar Cartelera',
      addBanner: 'Debes agregar un banner para tu cartelera',
      addType: 'Debes escoger un tipo de cartelera para continuar',
      deactivate: 'Desactivar Cartelera',
      editInfo: 'Editar Info de Cartelera',
      highlight: {
        title: 'Destaca un evento',
        description:
          'Selecciona un evento que quieras incentivar su venta para incluirlo en el inicio de tu cartelera.',
        description2:
          'El evento seleccionado se mostrará en el inicio de la cartelera.',
        descriptionAlreadyHighlighted:
          'Actualmente tienes otro evento destacado, al continuar lo reemplazarás por el evento seleccionado.',
        select: 'Selecciona un evento de la cartelera',
        disabled: 'Debes tener al menos 6 eventos en tu cartelera',
        event: 'Destacar evento',
        highlight: 'Destacar',
        remove: '¿Deseas quitar este evento de destacados?',
        removeDescription:
          'El evento dejará de aparecer en el inicio de tu cartelera',
        search: 'Buscar por evento o por ID',
        success: 'El evento se ha destacado exitosamente',
        removeSuccess: 'Evento destacado eliminado exitosamente',
      },
      searchBy: 'Buscar evento por nombre, id o fecha (DD MMMM YYYY)',
      addTag: 'Agregar etiqueta',
      editTag: 'Editar etiqueta',
      tagSuccess: 'Etiqueta agregada exitosamente',
      removeTagSuccess: 'Etiqueta eliminada exitosamente',
      removeFromBillboard: 'Eliminar de la cartelera',
      removeFromBillboardConfirmation:
        '¿Estás seguro de eliminar el evento "{{eventName}}" {{eventStartDate}}?',
      removeFromBillboardDescription:
        'Al eliminar el evento dejará de aparecer en tu cartelera.',
      relatedEvents: 'eventos relacionados',
      differentTimes: 'Varios horarios',
      differentStatuses: 'Varios estatus',
      addEvents: 'Agregar eventos',
      billboardUpdated: 'El estado de la cartelera se ha cambiado',
    },
  },
  bookings: {
    complete: 'Pago Completado',
    pending: 'Pendiente',
    canceled: 'Cancelada',
    tickets: 'Boletos',
    noInvoice: 'Sin folio',
    summary: 'Resumen de reservación',
    discount: 'Descuento',
    commission: 'Cargos por servicio',
    paymentMethodsCommission: 'Procesador de pagos',
    options: 'Opciones',
    ticket: 'Boleto',
    resendTickets: 'Reenviar boletos',
    downloadTickets: 'Descargar boletos',
    cancelReservation: 'Cancelar reservación',
    editBooking: 'Editar reservación',
    editBookingDescription:
      'Edita el nombre, apellido e email de la reservación',
    editLoading: 'Actualizando reservación...',
    editSuccess: 'Reservación actualizada exitosamente',
    editError: 'Ocurrió un error al actualizar la reservación',
    cancelDialog: {
      title: 'Cancelar reservación',
      description:
        'Por favor ingresa la cantidad a reembolsar y selecciona el motivo de la cancelación.',
      amount: 'Reembolso',
      amountPlaceholder: 'Ej. "0.00"',
      amountZero: 'No aplicable en reservación gratuita',
      reason: 'Motivo',
      confirm: 'Confirmar cancelación',
      reasons: {
        preventive: 'Preventivo',
        chargeback: 'Contracargo',
        cancelled_event: 'Evento cancelado',
        requested_by_organizer: 'Solicitado por el organizador',
        profeco: 'Profeco',
        special_case: 'Caso especial',
        postponed_event: 'Evento pospuesto',
        downtime: 'Inactividad',
      },
      schema: {
        requiredReason: 'Seleccione un motivo',
        requiredAmount: 'Ingrese el monto a reembolsar',
        invalidAmount: 'El monto debe ser menor o igual a ${{total}}',
      },
      confirmation: 'Esta acción no se puede deshacer.',
      confirmation2: '¿Estás seguro que deseas continuar?',
    },
  },
  holds: {
    title: 'Holds y cortesías',
    backUpTitle: 'Holds',
    description:
      'Crea y gestiona tus Holds (Apartados) de asientos para tu evento en diferentes zonas.',
    description2: 'Reserva Cortesías para tus invitados especiales.',
    createHold: 'Crear Hold',
    searchPlaceholder: 'Buscar hold por nombre',
    notFound: 'No se encontraron resultados',
    steps: {
      one: {
        title: '1. Crea un Hold',
        description:
          'Selecciona los asientos que deseas apartar, tu decides si necesitas agregar o quitar asientos.',
      },
      two: {
        title: '2. Convierte tus Holds en Cortesías',
        description:
          'Selecciona los asientos que necesites para generar tus cortesías, configura un nombre y el precio para tus boletos.',
      },
      three: {
        title: '3. Descarga tus boletos',
        description:
          'Después de configurar tus boletos, y generarlos, estos quedaran registrados en tus Reservaciones.',
      },
    },
    hold: 'Hold',
    batches: 'Cortesías generadas',
    available: 'Asientos disponibles',
    release: 'Liberar asientos',
    view: 'Ver cortesías',
    create: {
      title: 'Crear Hold',
      description:
        'Asigna un nombre único a tu Hold para identificarlo fácilmente.',
      description2:
        'Finalmente elige los asientos que deseas apartar para tu evento.',
      breadcrumb: {
        max: 'Aforo disponible',
        created: 'Holds ingresados',
      },
      form: {
        assign: 'Asigna un nombre a tu Hold para identificarlo fácilmente.',
        insert:
          'Ingresa la cantidad de boletos que deseas apartar por cada uno de los tipos de boleto.',
        name: 'Nombre del hold',
        create: 'Crear Hold',
        edit: 'Editar Hold',
        cancel: 'Cancelar',
        available: 'Disponibles',
        nameRequired: 'El nombre del hold es obligatorio',
        increaseCapacity: 'Aumenta el aforo desde la sección "Boletos".',
        minError: 'Debe haber {{min}} boletos mínimo',
        maxError: 'El límite son {{max}} boletos',
        overCapacityError: 'Superaste el aforo disponible',
        overLimitError: 'No puedes elegir más de {{limit}} boletos',
        held: 'Apartados en este hold',
      },
      loading: 'Creando Hold...',
    },
    edit: {
      title: 'Editar Hold',
      cancel: 'Cancelar',
      available: 'Disponibles',
      nameRequired: 'El nombre del hold es obligatorio',
      loading: 'Actualizando Hold...',
    },
    delete: {
      loading: 'Liberando asientos...',
    },
    success: {
      title: '¡Tu Hold ha sido creado!',
      subtitle: '¿Quieres convertir tus asientos apartados en Cortesías?',
      description:
        'Si deseas otorgar cortesías a tus invitados, puedes generar los boletos de tus asientos apartados de una vez.',
      buttonBack: 'En otro momento',
      buttonNext: 'Crear cortesías',
    },
    batch: {
      goBack: 'Regresar a editar Holds',
      batch: 'Grupos',
      title: 'Cortesías',
      createTitle: 'Estas creando tu cortesía',
      seat: 'asiento apartado',
      seats: 'asientos apartados',
      section: 'Sección',
      batchLabel: 'Nombre de tu cortesía',
      batchName: 'Ingresa el nombre de tus cortesías',
      batchSection: 'Elige una sección para crear tus cortesías',
      created: 'Cortesías creadas',
      ticketType: 'Tipo de boleto',
      price: 'Precio',
      name: 'Nombre',
      batchNameHelper: 'Es el nombre que aparecerá en tus boletos',
      modifyPrice: 'Selecciona el tipo de boleto y modifica el precio',
      originalPrice: 'Precio original',
      newPrice: 'Precio nuevo',
      selectSeats: 'Selecciona los asientos para tus invitados',
      selectAll: 'Seleccionar todos los asientos',
      noMap: {
        place: 'lugar apartado',
        places: 'lugares apartados',
        tickets: 'Boletos',
        selectedTickets: 'Tipos de boletos con lugares apartados:',
        emptyState: {
          title: 'Crear cortesías',
          text: 'Para comenzar a crear tus cortesías, selecciona un tipo de boleto de la lista de apartados disponible.',
        },
        createForm: {
          batchQuantity: 'Ingresa la cantidad de cortesías que deseas generar:',
          batchTicketName: 'Escribe el nombre que aparecerá en los boletos:',
          priceModify: 'Modifica el precio',
          placeholders: {
            batchTicketName: 'Ej: Juan Pérez',
          },
          sublabels: {
            priceModify: {
              original: 'Precio original',
              new: 'Precio nuevo',
            },
          },
          exit: 'Quitar',
          buttons: {
            create: 'Crear cortesías',
            cancel: 'Cancelar',
          },
          ticketType: 'Tipo de boleto',
          noSelected: 'Sin seleccionar',
        },
        confirm: {},
      },

      confirm: {
        title: 'Confirma tus cortesías a partir del hold:',
        name: 'Quedan a nombre de ',
      },
      errors: {
        max_limit: 'Superaste el limite de creación de {{limit}} cortesías',
      },
      error: 'Ocurrió un error al crear las cortesías',
      dialogName: 'Nombre de tus cortesías',
      dialogTicketAmount: 'no. boletos',
      yup: {
        name: 'Ingresa el nombre de tus cortesías',
        attendee: 'Ingresa el nombre que aparecerá en tus cortesías',
        section: 'Elige una sección para crear tus cortesías',
        seats: 'Elige al menos un asiento',
        ticketType: 'Elige un tipo de boleto',
        price: 'Ingresa un precio',
        quantity: 'Ingresa la cantidad de cortesías que deseas generar',
      },
      downloadTickets:
        'Los enlaces PDF de tus boletos se exportarán a un archivo de Excel',
      ticketsPrintable:
        'Estos boletos fueron creados para imprimir, no hay enlaces de descarga',
      printableLabel: 'Imprimir cortesías',
      goHolds: 'Ir a Holds y Cortesías',
      goBookings: 'Ir a Reservación',
      assistant: 'Asistente',
      ticket: 'Boleto',
      folio: 'Folio',
      newBatch: 'Crear nuevas cortesías',
      noTickets:
        'No hay boletos para seleccionar. Crea un boleto para continuar.',
    },
    multiple: {
      zones: 'Múltiples zonas',
      tickets: 'Múltiples boletos',
    },
    batchInProcess: 'Creación de cortesía en proceso',
    refreshPage: 'Recarga la página para ver los cambios',
  },
  payments: {
    title: 'Pagos',
    paymentMethods: 'Métodos de pago',
    alerts: {
      dispersals: 'Para recibir las dispersiones de tu evento necesitamos',
      bankAccount: ' una <navigate>cuenta bancaria</navigate> ',
      taxData: ' tus <navigate>datos fiscales</navigate>',
      success: 'Se ha agregado tu método de pago',
      error: 'Ocurrió un error al agregar el método de pago',
      confirmDelete: '¿Estas seguro de eliminar este método de pago?',
      deleteSuccess: 'El método de pago ha sido eliminado',
      deleteError: 'Ocurrió un error al eliminar el método de pago',
    },
    oxxo: {
      title: 'Pago en Oxxo',
      description:
        'Tus asistentes podrán realizar el pago de sus boletos desde cualquier Oxxo.',
    },
    cash: {
      title: 'Pago en efectivo',
      description:
        'Tus asistentes podrán realizar el pago de sus boletos en efectivo.',
    },
    card: {
      title: 'Tarjeta de crédito o débito',
      description:
        'Tus asistentes podrán realizar pagos con tarjetas bancarias Visa, Mastercard y American Express.',
    },
    exclusiveSales: {
      title: 'Ventas exclusivas',
      description:
        'Ofrece beneficios exclusivos a clientes que compren con tarjetas de crédito y mejora la experiencias de compra.',
      preSale: {
        title: 'Preventa con banco(s)',
        description:
          'Ofrece una venta temprana a los asistentes potenciales de tu evento.',
        description2:
          'Podrás configurar ofertas exclusivas con tarjetas participantes.',
        installmentPlans:
          'La comisión se absorberá por el organizador y se descontará de la dispersión final.',
        success: 'Preventa configurada exitosamente',
        error: 'Ocurrió un error al configurar la preventa',
        loading: 'Configurando preventa...',
        deactivate: 'Desactivar preventa',
        deactivateConfirmation: '¿Estás seguro de desactivar la preventa?',
        deactivateDescription:
          'La configuración de la preventa será eliminada.',
        deactivateSuccess: 'Preventa desactivada exitosamente',
        deactivateError: 'Ocurrió un error al desactivar la preventa',
      },
      installmentPlans: {
        title: 'Meses sin intereses',
        description:
          'Crea un plan de mensualidades exclusiva con tarjetas participantes.',
        description2:
          'La comisión será absorbida por el organizador y se añadirá a los cargos por servicio del evento.',
        seeCommissions: 'Ver comisiones',
        3: 'Hasta 3 meses',
        6: 'Hasta 6 meses',
        9: 'Hasta 9 meses',
        12: 'Hasta 12 meses',
        18: 'Hasta 18 meses',
        minSale: 'Importe mínimo de compra',
        min: 'Importe mínimo de compra: ${{amount}}MXN',
        success: 'Plan de mensualidades configurado exitosamente',
        error: 'Ocurrió un error al configurar el plan de mensualidades',
        loading: 'Configurando plan de mensualidades...',
        deactivate: 'Desactivar plan de mensualidades',
        deactivateConfirmation:
          '¿Estás seguro de desactivar el plan de mensualidades?',
        deactivateDescription:
          'La configuración del plan de mensualidades será eliminada.',
        deactivateSuccess: 'Plan de mensualidades desactivado exitosamente',
        deactivateError:
          'Ocurrió un error al desactivar el plan de mensualidades',
        alert:
          'Preventa tiene un plan de mensualidades activo. Si deseas incluir MSI, deberá ser en días posteriores a la preventa',
      },
      participatingBanks: {
        title: 'Bancos participantes',
        description:
          'Paso 1: Selecciona uno o varios bancos con sus tarjetas participantes',
      },
      preSalePromotion: {
        title: 'Promoción en Preventa',
        description:
          'Paso 2: Incluye una promoción con el banco participante (Opcional)',
        msi: 'Activa meses sin intereses',
      },
      duration: {
        description: 'Paso 3: ¡Último paso!, configura los días de duración',
      },
      msiPromotion: {
        description: 'Paso 2: Configura las cuotas a ofrecer',
      },
    },
    addPaymentMethod: 'Agregar otro método de pago',
    addBank: 'Añadir Banco',
    addCard: 'Añadir Tarjeta',
    editCard: 'Editar Tarjeta',
    addAnotherCard: 'Agregar otra tarjeta',
    bankName: 'Nombre del banco',
    bankCode: 'Código del banco',
    cardType: 'Tipo de tarjeta',
    credit: 'Crédito',
    debit: 'Débito',
    allCards: 'Todas las tarjetas {{bank}}',
    allBanks: 'Todos los bancos',
    productName: 'Nombre de la tarjeta',
    binPlaceholder: 'BIN (Primeros 5-6 dígitos)',
    commissions: 'Comisiones',
    commissionsDescription:
      'La comisión dependerá de las cuotas a ofrecer y esta se absorberá por el organizador y se descontará de la dispersión final.',
    validations: {
      requiredBanks: 'Debes seleccionar al menos un banco',
      requiredCards: 'Debes seleccionar al menos una tarjeta',
    },
  },
  rsvp,
  checkinAlert: {
    title: '¡Haz Check-in!',
    description:
      'Administra tus registros desde admin en la sección <app>control de eventos</app> y desde la app Check-in controla el acceso a tus eventos. Disponible para <android>Android</android> y <ios>iOS</ios>.',
  },
  checkin: {
    eventsTableHead: {
      registeredUsers: 'Asistentes registrados',
      accessLevels: 'Puntos de acceso',
      accessLevel: 'Punto de acceso',
      staff: 'Staff',
    },
    manualRegistration:
      '¿Estás seguro de querer registrar a <strong>{{attendee}}</strong> en el punto de acceso <strong>{{checkpoint}}</strong>?',
    editAccessLevels: 'Actualizar niveles de acceso',
    eventDetails: 'Detalles del evento',
    modal: {
      title: 'Niveles de acceso',
      submit: 'Actualizar niveles de acceso',
      submitEdit: 'Actualizar nivel de acceso',
      showAddStaff: 'Agregar nuevo Staffer',
      addStaff: 'Agregar Staff',
      addCheckpoint: 'Agrega un punto de acceso',
      submitStaff: 'Agregar staff',
      successStaff: 'Staffer agregado exitosamente',
      errorStaff: 'Ocurrió un error al agregar el staffer',
      name: 'Nombre',
      level: 'Nivel',
      lastname: 'Apellido',
      email: 'Correo electrónico',
      selectGroups: 'Selecciona los niveles de acceso',
      addAccessPoints: 'Selecciona o agrega puntos de acceso',
      addTicketTypes: 'Agrega tipos de boletos',
      tickets: 'Boletos',
      staff: 'Staff',
      addLevel: 'Agrega un punto de acceso',
      stafferDisabled:
        'Para agregar staffers selecciona máximo un punto de acceso por nivel.',
      staffAlreadyAdded:
        'Este staffer ya está asignado al checkpoint {{checkpoint}}.',
      staffAdded: 'Staffer agregado exitosamente al checkpoint {{checkpoint}}.',
      ticketsAdded:
        'Boletos agregados exitosamente al checkpoint {{checkpoint}}.',
      newLevelAdded: 'Nivel de acceso agregado exitosamente.',
    },
    checkpointUpdateWarning: {
      title: '¿Estás seguro de querer actualizar este punto de acceso?',
      titlePlural: '¿Estás seguro de querer actualizar estos puntos de acceso?',
      subtitle:
        'Si existen boletos o staffers previamente asignados, estos serán eliminados en caso de no haber sido seleccionados nuevamente.',
    },
    checkpointUpdated: 'El checkpoint {{checkpoint}} ha sido actualizado.',
    addStaffOne: 'Agregarás a',
    addStaffTwo: 'como staffer al nivel de acceso',
    addStaffThree:
      'Este staffer será removido de cualquier otro nivel de acceso.',
    confirmDeleteCheckpoint: '¿Estás seguro de eliminar este checkpoint?',
    checkpointDeleted:
      '{{checkpoint}} eliminada con éxito. Los boletos y colaboradores asociados han sido removidos.',
    errorDeleteCheckpoint: 'Ocurrió un error al eliminar {{checkpoint}}.',
    attendees: {
      modal: {
        title: 'Niveles de acceso',
        description: 'Elige el nivel de acceso para registrar al asistente.',
        success: 'Asistente registrado exitosamente',
      },
      total: 'Total',
      registered: 'Registrados',
      pending: 'Pendientes',
      ticketTypes: 'Tipos de boletos',
      accessDetail: 'Detalle de accesos',
      accessBy: 'Acceso por',
      placeholder: 'Buscar por nombre, email, id o folio',
      noAttendees: 'Aún no hay asistentes',
      noReservations: 'No se encontraron asistentes',
      noCheckpoints: 'Este boleto aún no tiene puntos de acceso asignados',
      accessedThrough: 'Accedió por:',
      accessDeleted: 'Acceso eliminado exitosamente',
      confirmAccessDelete:
        '¿Estás seguro de querer eliminar a <strong>{{attendee}}</strong> del punto de acceso <strong>{{checkpoint}}</strong>?',
      mergeAlert:
        '<strong>Merge:</strong> <parent>{{parentName}} ({{parentId}})</parent> se ha combinado con otro evento. Ahora puedes encontrar los registros de asistentes de ambos eventos en un solo lugar.',
      mergeAlertPlural:
        '<strong>Merge:</strong> <parent>{{parentName}} ({{parentId}})</parent> y otros <strong>{{childrenLength}} eventos ({{childrenIds}})</strong> se han combinado. Ahora puedes encontrar los registros de asistentes de estos eventos en un solo lugar.',
      reportName: 'reporte_asistentes_{{eventId}}',
      noTickets: 'Aún no hay registro de accesos por tipo de boleto',
    },
    records: {
      attendee: 'Asistente',
      email: 'Correo electrónico',
      code: 'No. de reservación',
      status: 'Estatus',
      date: 'Hora y fecha',
      source: 'Desde',
      remark: 'Observación',
      checker: 'Operador',
      placeholder: 'Buscar por nombre, email, folio u operador',
      noRecords: 'No se encontraron registros',
      observations: {
        check: 'Validación realizada con éxito',
        checkin: 'Check-in realizado con éxito en {{checkpoint}}',
        checkout: 'Check-out realizado con éxito',
      },
    },
    merge: {
      mergeTitle: 'Merge: Combina y Unifica tus Eventos',
      mergeDescription:
        'Selecciona un evento principal y agrega eventos secundarios para simplificar la gestión.',
      fatherTableTitle: 'Elige el evento principal:',
      mergePlaceholder: 'Buscar eventos por nombre, ID, fecha',
      childrenTableTitle: 'Elige el o los eventos secundarios:',
      childrenTitle: 'Eventos secundarios',
      validEvent: 'Selecciona un evento a enlazar',
      selectedOne: 'Evento seleccionado',
      selectedMultiple: 'Eventos seleccionados',
      merge: 'Combinar',
      split: 'Separar eventos',
      mergeSuccess: 'Los eventos han sido enlazados exitosamente',
      mergeError: 'Ocurrió un error al enlazar los eventos',
      mergeSuccessDescription:
        'Los cambios podrían tardar unos minutos en reflejarse en los detalles de los eventos.',
      infoModal: {
        title: 'Gestiona y configura tus eventos',
        features: {
          combineEvents: 'Combina 2 o más eventos en uno solo',
          addAccessPoints: 'Agrega puntos de acceso principales y secundarios',
          trackAttendees:
            'Mantén un seguimiento detallado de tus asistentes con un listado de check-in',
          visualizeStaff:
            'Visualiza el personal asignado a cada evento configurado',
        },
      },
      confirmationModal: {
        title: '¿Estás seguro de que deseas combinar estos eventos?',
        description:
          'Esta acción fusionará los datos y configuraciones de los eventos secundarios <strong>en el evento principal seleccionado.</strong>',
        confirm:
          '<strong>Confirma</strong> tu elección para proceder con el merge.',
      },
      successModal: {
        title: 'La combinación de tus eventos estará lista pronto...',
        description:
          'Estamos trabajando arduamente en combinar tus eventos. <br/><strong>Este proceso puede tardar varios minutos,</strong> pero una vez que hayamos completado la unificación de datos, podrás encontrar el evento combinado en tu lista de control de eventos.',
      },
      removeChildModal: {
        title:
          '¿Estás seguro de querer separar <strong>{{child}}</strong> de <strong>{{parent}}</strong>?',
        description:
          'Al separar este evento, se actualizarán los datos y volverá de manera independiente al listado de eventos.',
        success: 'La separación de tu evento estará lista pronto...',
        successMessage:
          'Estamos trabajando en el proceso de separar tu evento <strong>{{child}}</strong> del evento principal <strong>{{parent}}.</strong><br/> <strong>Este proceso puede tardar varios minutos,</strong> pero una vez que hayamos completado la liberación, podrás encontrar el evento <strong>{{child}}</strong> en tu lista de control de eventos, con datos actualizados y listo para su gestión independiente.',
      },
      removeAllModal: {
        title: '¿Estás seguro de liberar todos tus eventos?',
        description:
          'Al separar estos eventos, se actualizarán los datos y volverán de manera independiente al listado de eventos.',
        success: 'La liberación de tus eventos estará lista pronto...',
        successMessage:
          'Estamos trabajando en el proceso de liberación de tus eventos combinados. <br/><strong>Este proceso puede tardar varios minutos,</strong> pero una vez que hayamos completado la liberación, podrás encontrar los eventos en la lista de control de eventos, con datos actualizados y listos para su gestión independiente.',
      },
    },
    requiredCheckpointName: 'Ingresa el nombre del punto de acceso',
    requiredLevel: 'Ingresa un nivel de acceso',
    leave: 'Salir de Check In',
    redirectModal: {
      description:
        'La asistentes y puntos de acceso de este evento han sido asignados a <strong>{{parentName}} ({{parentEventId}})</strong>.',
      continue: 'Ir a evento principal',
      cancel: 'Ir a lista de eventos',
    },
  },
  ...esMap,
  ...esCampaigns,
  notFound,
  forbidden,
  errors,
};

export default es;
