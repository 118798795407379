import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
import {
  useResponsive,
  useCollapseDrawer,
  cssStyles,
  Logo,
  Scrollbar,
  NavSectionVertical,
} from '@boletia/blt-ui';
import { NAVBAR } from '@boletia/blt-ui/src/config';
import NavbarAccount from '../../components/NavbarAccount';
import CollapseButton from '../../components/CollapseButton';
import NavbarDocs from './NavbarDocs';
import EventDetailsIcons from '../../../modules/events/components/EventDetailsIcons';
import SuperAdminComponent from '../../../modules/superadmin/components/SearchComponent';
import useAuth from '../../../hooks/useAuth';
import useEvent from '../../../hooks/useEvent';
import ExclusiveEvent from '../../components/ExclusiveEvent';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

EventNavbarVertical.propTypes = {
  navConfig: PropTypes.any,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function EventNavbarVertical({
  navConfig,
  isOpenSidebar,
  onCloseSidebar,
}) {
  const theme = useTheme();

  const { rsvp_private: isPrivateEvent } = useEvent();

  const { pathname } = useLocation();

  const {
    user: { super_admin: isSuperAdmin },
  } = useAuth();

  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  const showIcons = isMobile && !pathname.includes('/dashboard');

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const showSuperAdmin = isSuperAdmin && !isCollapse;

  const renderContent = (
    <Scrollbar
      sx={{
        'height': 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
        <Stack gap="16px">
          <NavbarAccount isCollapse={isCollapse} />
          {isPrivateEvent && <ExclusiveEvent />}
          {showSuperAdmin && <SuperAdminComponent />}
        </Stack>
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
      {showIcons && <EventDetailsIcons />}
      {!isCollapse && <NavbarDocs />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
