import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { PlusIcon } from '@boletia/blt-components/src/Icons';
import TrashIcon from '@boletia/blt-components/src/Icons/TrashIcon';

function CustomInput({
  type,
  questionName,
  setQuestionName,
  helpText,
  setHelpText,
  options,
  setOptions,
}) {
  const optionText = useRef(null);
  const input = useRef();
  const [option, setOption] = useState('');
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertQ, setAlertQ] = useState(false);

  const addOption = () => {
    if (option !== '') {
      setOptions([...options, { option_value: option }]);
      setOption('');
      optionText.current.children[0].value = '';
    } else {
      setError(true);
    }
  };

  const deleteOption = (id) => {
    const copyOptions = [...options];
    copyOptions.splice(id, 1);
    setOptions(copyOptions);
  };

  return (
    <>
      {type !== 'select' ? (
        <Box
          sx={{
            width: '100%',
            background: '#F4F6F8',
            borderRadius: '8px',
            border: '1px solid #cfd8e2',
            position: 'relative',
          }}
        >
          <InputBase
            disabled
            fullWidth
            placeholder={questionName}
            sx={{ padding: '12px 14px 11px 14px' }}
            multiline={type === 'text_area'}
            rows={type === 'text_area' ? 3 : 1}
          />
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              top: -18,
              left: 12,
              background: '#FFF',
              border: !alertQ ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="titulo de la pregunta"
            value={questionName}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlertQ(true);
              } else {
                setAlertQ(false);
                setQuestionName(e.target.value);
              }
            }}
          />
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              bottom: -18,
              left: 12,
              background: '#FFF',
              border: !alert ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="Texto de ayuda"
            value={helpText}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlert(true);
              } else {
                setAlert(false);
                setHelpText(e.target.value);
              }
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Select sx={{ width: '100%' }}>
            <MenuItem
              sx={{
                display: 'flex',
                background: '#FFF',
                margin: '0px auto',
                borderRadius: '8px',
                width: '95%',
              }}
            >
              <Focusable
                optionText={optionText}
                reference={input}
                setOption={setOption}
                setError={setError}
                option={option}
                addOption={addOption}
                error={error}
              />
            </MenuItem>
            {options.map((opt, i) => (
              <Item
                key={opt?.option_value}
                label={opt?.option_value}
                onDelete={() => deleteOption(i)}
              />
            ))}
            <MenuItem
              sx={{
                display: 'flex',
                background: '#FFF',
                margin: '0px auto',
                borderRadius: '8px',
                width: '95%',
              }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  addOption(e);
                  input.current?.focus();
                }}
                startIcon={<PlusIcon sx={{ width: 10, height: 10 }} />}
              >
                Agregar opción
              </Button>
            </MenuItem>
          </Select>
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              top: -18,
              left: 12,
              background: '#FFF',
              border: !alertQ ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="Pregunta"
            value={questionName}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlertQ(true);
              } else {
                setAlertQ(false);
                setQuestionName(e.target.value);
              }
            }}
          />
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              bottom: -18,
              left: 12,
              background: '#FFF',
              border: !alert ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="Texto de ayuda"
            value={helpText}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlert(true);
              } else {
                setAlert(false);
                setHelpText(e.target.value);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}

CustomInput.propTypes = {
  type: PropTypes.string,
  questionName: PropTypes.string,
  setQuestionName: PropTypes.func,
  helpText: PropTypes.string,
  setHelpText: PropTypes.func,
  options: PropTypes.array,
  setOptions: PropTypes.func,
};

CustomInput.defaultProps = {
  type: '',
  questionName: '',
  setQuestionName: () => {},
  helpText: '',
  setHelpText: () => {},
  options: [],
  setOptions: () => {},
};

export default CustomInput;

export function Focusable({
  optionText,
  reference,
  error,
  setOption,
  setError,
  option,
  addOption,
}) {
  useEffect(() => {
    reference?.current?.focus();
  }, []);
  return (
    <InputBase
      autoFocus
      inputRef={reference}
      ref={optionText}
      sx={{
        'width': '100%',
        'border': !error ? '1px solid #F4F6F8' : '1px solid #B72136',
        'padding': '12px',
        'borderRadius': '8px',
        '&:hover': {
          backgroundColor: 'white',
        },
      }}
      value={option}
      onChange={(e) => {
        setOption(e.target.value);
        setError(false);
      }}
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onKeyPress={(e) => {
        e.stopPropagation();
        if (e.code === 'Enter') addOption(e);
      }}
    />
  );
}

Focusable.propTypes = {
  optionText: PropTypes.object,
  reference: PropTypes.object,
  error: PropTypes.bool,
  setOption: PropTypes.func,
  setError: PropTypes.func,
  option: PropTypes.string,
  addOption: PropTypes.func,
};

export function Item({ label, onDelete }) {
  return (
    <MenuItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: '#F4F6F8',
        padding: '12px',
        margin: '4px auto',
        borderRadius: '8px',
        width: '95%',
      }}
      value={label}
    >
      <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <TrashIcon />
      </IconButton>
    </MenuItem>
  );
}

Item.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
};

Item.defaultProps = {
  label: '',
  onDelete: () => {},
};