import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, Collapse, Typography } from '@mui/material';
import { InfoIcon, PlusIcon } from '@boletia/blt-components/src/Icons';
import DynamicForm from './DynamicForm';
import { useCreateReservationQuestionsMutation } from '../../../store/api/surveys-api';
import ReservationForm from './Modals/ReservationForm';

function ReservationFormTemplate({
  questionList,
  setQuestionList,
  handleModal,
  cancelReservationForm,
  canEdit,
}) {
  const [editData, setEditData] = useState(null);
  const [startEdit, setStartEdit] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [open, setOpen] = useState(true);

  const params = useParams();

  const [addQuestions] = useCreateReservationQuestionsMutation();

  const handleSetEditData = (d, index) => {
    const copyQuestions = [...questionList];
    copyQuestions[index] = { ...d };
    setQuestionList([...copyQuestions]);
  };

  const handleSubmit = (d) => {
    setQuestionList([...questionList, d]);
    setStartEdit(true);
  };

  const submitQuestions = (questions, text) => {
    const data = { eventId: params.event, questions };
    addQuestions(data)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.error) {
          handleModal(true, response.error.data.message, true);
        } else {
          handleModal(true, text);
        }
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ marginBottom: '16px' }}>
          Pregunta de registro por reservación &nbsp;
          <InfoIcon
            fontSize="small"
            htmlColor="#757575"
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setOpen(!open);
            }}
          />
        </Typography>
        {canEdit && (
          <Button
            variant="contained"
            color="info"
            disabled={questionList.length > 16}
            onClick={() => setOpenForm(true)}
          >
            <PlusIcon sx={{ width: 13, height: 13 }} /> &nbsp; &nbsp;Agregar
            pregunta
          </Button>
        )}
      </Box>
      <Collapse in={open} sx={{ margin: '20px 0 24px 0' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Alert severity="info">
            <Typography variant="label3">
              <Typography component="span" sx={{ fontWeight: '600' }}>
                Nombre, Apellido y Email &nbsp;
              </Typography>
              son campos por defecto, no es necesario que los agregues.
            </Typography>
          </Alert>
          <Alert severity="info">
            Las preguntas por reservación que configures aparecerán de manera
            automática al asistente en el proceso de compra.
          </Alert>
        </Box>
      </Collapse>
      <ReservationForm
        open={openForm}
        handleModal={setOpenForm}
        numberOfQuestions={questionList.length}
        value={editData}
        onSubmit={(d) => handleSubmit(d)}
      />
      <DynamicForm
        questionList={questionList}
        setQuestionList={setQuestionList}
        submitQuestions={submitQuestions}
        onCancel={cancelReservationForm}
        editData={handleSetEditData}
        startEdit={startEdit}
        setStartEdit={setStartEdit}
        canEdit={canEdit}
      />
    </Box>
  );
}

ReservationFormTemplate.propTypes = {
  questionList: PropTypes.array,
  setQuestionList: PropTypes.func,
  handleModal: PropTypes.func,
  cancelReservationForm: PropTypes.func,
  canEdit: PropTypes.bool,
};

ReservationFormTemplate.defaultProps = {
  questionList: [],
  setQuestionList: () => {},
  handleModal: () => {},
  cancelReservationForm: () => {},
  canEdit: true,
};

export default ReservationFormTemplate;
