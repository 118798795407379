import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CrwonIcon from '../../modules/rspv/components/dashboard/assets/CrwonIcon';

export default function ExclusiveEvent(props) {
  return (
    <Box
      sx={{
        border: '1px solid #8282FF',
        borderRadius: '12px',
        padding: '8px 0px',
      }}
    >
      <Stack alignItems="center" gap="8px">
        <CrwonIcon />
        <Typography variant="subtitle2" color="primary">
          Evento exclusivo
        </Typography>
      </Stack>
    </Box>
  );
}
