import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { Modal } from '@boletia/blt-components/src/Modal';
import { Message } from '@boletia/blt-components/src/Message';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  FormSelect,
  FormsWrapper,
  FormText,
} from '@boletia/blt-components/src/Forms';
import useUIMessages from '../../../hooks/useUIMessages';
import FormDraggableItem from './FormDraggableItem';
import EditForm from './Edit/EditForm';

const WarningModal = ({ open, handleAccpet, handleCancel }) => (
  <Modal
    color="primary"
    open={open}
    confirmation="Aceptar"
    handleAccept={handleAccpet}
    confirmationCancel="Cancelar"
    handleCancel={handleCancel}
  >
    <Message
      title="Eliminarás todas tus preguntas"
      subtitle="¿Estas seguro?"
      severity="warning"
      filled
    />
  </Modal>
);

function DynamicForm({
  questionList,
  setQuestionList,
  submitQuestions,
  onCancel,
  editData,
  startEdit,
  setStartEdit,
  ticketTypesList,
  canEdit,
}) {
  const [allDeleted, setAllDeleted] = useState([]);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [indexEdit, setIndexEdit] = useState(-1);
  const [questionData, setQuestionData] = useState({});
  const [open, setOpen] = useState(false);

  const { translateSnackbar } = useUIMessages();

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((question, i) => {
      const copyQuestion = { ...question };
      delete copyQuestion.position;
      return { ...copyQuestion, position: i };
    });
  };

  const reorderQuestions = (result) => {
    setStartEdit(true);
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;
    setQuestionList((prevQuestionList) =>
      reorder(prevQuestionList, source.index, destination.index),
    );
  };

  const formatData = () => {
    const copyQuestions = [...questionList];
    const optionQuestions = copyQuestions.filter((question) =>
      Object.prototype.hasOwnProperty.call(question, 'options')
        ? question.options !== ''
        : false,
    );

    const isOptionsEmpty = optionQuestions.find((question) => {
      if (!question?.options) {
        return false;
      }
      return question.options.length < 1;
    });

    if (isOptionsEmpty) {
      translateSnackbar('admin.pages.surveys.actions.submit.warning', {
        variant: 'error',
      });
      return;
    }

    const ordened = copyQuestions.map((question, i) => {
      const copyQuestion = { ...question };
      delete copyQuestion.position;
      return { ...copyQuestion, position: i };
    });

    const parseOptions = ordened.map((item) => {
      const optns = item.options;
      const parseItem = JSON.stringify(optns);
      const copyOptions = { ...item };
      copyOptions.options = parseItem;
      return copyOptions;
    });

    const newData = {
      new: parseOptions.filter(
        (question) =>
          !Object.prototype.hasOwnProperty.call(question, 'question_id'),
      ),
      update: parseOptions.filter((question) =>
        Object.prototype.hasOwnProperty.call(question, 'question_id'),
      ),
      destroy: deletedQuestions,
    };
    submitQuestions(newData);
  };

  const deleteQuestion = (id) => {
    const questionListCopy = [...questionList];
    const index = questionListCopy.findIndex(
      (question) => question.question_title === id,
    );
    const deleted = questionListCopy.splice(index, 1)[0];
    setAllDeleted([...allDeleted, deleted]);
    if (Object.prototype.hasOwnProperty.call(deleted, 'question_id')) {
      setDeletedQuestions([...deletedQuestions, deleted.question_id]);
    }
    setStartEdit(true);
    setQuestionList([...questionListCopy]);
    if (!questionListCopy.length > 0) {
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setQuestionList([...allDeleted]);
  };

  const handleAccept = () => {
    submitQuestions(
      {
        new: [],
        update: [],
        destroy: [...deletedQuestions],
      },
      'eliminadas',
    );
    setOpen(false);
  };

  const questions = questionList
    .sort((question, question2) => question.position - question2.position) // se ordenan las pergauntas
    .map((question, i) => {
      const onEdit = () => {
        // create a funciton onedit for each question
        setQuestionData(question);
        setIndexEdit(i);
      };
      return indexEdit === i ? (
        <ListItem sx={{ padding: 0 }}>
          <EditForm
            type={question.question_type}
            onCancel={() => setIndexEdit(-1)}
            data={questionData}
            onSave={editData}
            index={i}
            setStartEdit={setStartEdit}
            ticketData={ticketTypesList}
          />
        </ListItem>
      ) : (
        <Draggable
          key={question.question_title}
          draggableId={question.question_title}
          index={i}
        >
          {(draggableProvided) => (
            <ListItem
              {...draggableProvided.draggableProps}
              ref={draggableProvided.innerRef}
              sx={{
                padding: '0',
              }}
            >
              <FormDraggableItem
                id={question.question_title}
                draggableSection={{ ...draggableProvided.dragHandleProps }}
                onDelete={deleteQuestion}
                onEdit={onEdit}
                ticketTypes={question.ticket_types}
                ticketTypesList={ticketTypesList}
                canEdit={canEdit}
              >
                <Grid item md={12} xs={12} sx={{ color: '#919EAB' }}>
                  {question.question_type === 'text' && (
                    <Box
                      sx={{
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    >
                      <FormText
                        name={question.question_title}
                        label={question.question_title}
                        sx={{ background: '#fff' }}
                      />
                      <Box sx={{ padding: '0 10px' }}>
                        <Typography
                          variant="caption"
                          sx={{
                            lineHeight: 1,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {question.help_text}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {question.question_type === 'text_area' && (
                    <Box
                      sx={{
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    >
                      <FormText
                        name={question.question_title}
                        label={question.question_title}
                        multiline
                        rows={3}
                        sx={{ background: '#fff' }}
                      />
                      <Box
                        sx={{
                          padding: '0 5px',
                        }}
                      >
                        <Typography variant="caption">
                          {question.help_text}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {question.question_type === 'select' && (
                    <Box
                      key={question.question_title}
                      sx={{
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    >
                      <FormSelect
                        name={question.question_title}
                        label={question.question_title}
                        sx={{ background: '#fff' }}
                        multiline
                        rows={3}
                      >
                        <option hidden selected>
                          Click en editar para agregar opciones
                        </option>
                        {question.options.map((option) => (
                          <option
                            value={option.option_value}
                            key={option.option_value}
                          >
                            {option.option_value || option.value}
                          </option>
                        ))}
                      </FormSelect>
                      <Box sx={{ padding: '0 10px' }}>
                        <Typography
                          variant="caption"
                          sx={{
                            lineHeight: 1,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {question.help_text}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </FormDraggableItem>
            </ListItem>
          )}
        </Draggable>
      );
    });

  return (
    <>
      <WarningModal
        open={open}
        handleAccpet={handleAccept}
        handleCancel={handleCancel}
      />
      <Card>
        <Box
          sx={{
            padding: { xs: '10px', md: '32px' },
          }}
        >
          <Typography variant="h4">Preguntas</Typography>
          <DragDropContext onDragEnd={(result) => reorderQuestions(result)}>
            <FormsWrapper>
              <Droppable droppableId="questions">
                {(droppableProvided) => (
                  <List
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    {questions}
                    {droppableProvided.placeholder}
                  </List>
                )}
              </Droppable>
              {questions.length > 0 && (
                <Grid
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      padding: '18px 0 0 18px',
                      width: { xs: '100%', md: '400px' },
                      display: 'flex',
                      gap: '15px',
                    }}
                  >
                    <Button
                      disabled={!canEdit}
                      variant="outlined"
                      rounded="rounder"
                      fullWidth
                      onClick={() => onCancel()}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      rounded="rounder"
                      border="small"
                      color="primary"
                      fullWidth
                      disabled={!startEdit}
                      onClick={() => {
                        formatData();
                      }}
                    >
                      Guardar y publicar
                    </Button>
                  </Grid>
                </Grid>
              )}
            </FormsWrapper>
          </DragDropContext>
        </Box>
      </Card>
    </>
  );
}

DynamicForm.propTypes = {
  questionList: PropTypes.array,
  initialList: PropTypes.array,
  children: PropTypes.element,
  submitQuestions: PropTypes.func,
  onCancel: PropTypes.func,
  editData: PropTypes.func,
  setQuestionList: PropTypes.func,
  reference: PropTypes.any.isRequired,
  startEdit: PropTypes.bool,
  setStartEdit: PropTypes.func,
};

DynamicForm.defaultProps = {
  questionList: [],
  initialList: [],
  setQuestionList: () => {},
  submitQuestions: () => {},
  onCancel: () => {},
  editData: () => {},
  children: <></>,
  startEdit: false,
  setStartEdit: () => {},
};

export default DynamicForm;
