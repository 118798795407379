const esMap = {
  map: {
    title: 'Mapa',
    modal: {
      subtitle: 'Visualiza el mapa de tu evento',
      description:
        'Consulta el mapa de tu evento como lo verán los asistentes en nuestra plataforma. Comunícate con nosotros si necesitas esta funcionalidad.',
    },
    breadcrumbs: {
      settings: 'Ajustes',
      eventMap: 'Mapa del evento',
      totalTickets: 'Boletos emitidos',
    },
    noMapSelected: {
      title: 'Evento sin mapa seleccionado',
      description: 'Seleccione un mapa para continuar',
    },
    mapZone: {
      tabs: {
        zones: 'Zonas',
        holds: 'Disponibilidad',
      },
      cta: 'Ir a filtrar holds',
    },
    holdSideBar: {
      title: 'Holds',
      description: 'Elije la sección del recinto con boletos apartados',
      checkAll: 'Ver todos los boletos apartados (holds)',
      cta: 'Filtrar selección',
      availableSeats: 'asientos disponibles de',
      noSeats: 'Sin asientos disponibles',
      capacity: 'del aforo -',
      emptyState: {
        title: 'No se encontraron holds',
        description: 'Al parecer aún no has creado holds para este evento',
        cta: 'Crear holds',
      },
    },
    zoneSideBar: {
      title: 'Zonas del recinto',
      description: 'Visualiza las zonas que tienes en el recinto',
      seats: 'asientos -',
    },
  },
};

export default esMap;
