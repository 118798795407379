/* eslint-disable no-template-curly-in-string */
export const esCampaigns = {
  campaigns: {
    campaigns: 'Campañas',
    results: 'Resultado de mis campañas ',
    campaign: 'Campaña',
    balance: 'Saldo disponible',
    recharge: 'Recargar',
    investment: 'Inversión',
    income: 'Ingresos',
    soldTickets: 'Boletos vendidos',
    roas: 'ROAS',
    description:
      '<strong>Promociona tu evento</strong> en miles de páginas web y <strong>aumenta tus ventas</strong>',
    reach: 'Llega a más sitios web, blogs y revistas online.',
    noLimit: '¡No te limites solo a las redes sociales!',
    loading: 'Cargando campañas...',
    focus: {
      0: 'Nuestras campañas están enfocadas a:',
      1: '<strong>Buscar nuevas personas</strong> con interés en tu evento.',
      2: '<strong>Encontrar asistentes similares</strong> a quienes ya te compraron en otras plataformas.',
      3: '<strong>Recuperar asistentes interesados</strong> en la página de tu evento.',
    },
    createCampaign: 'Crear campaña',
    editCampaign: 'Editar campaña',
    createAd: 'Crear anuncio',
    dates: 'Fechas',
    status: 'Estado',
    impressions: 'Impresiones',
    clicks: 'Clics',
    actions: 'Acciones',
    tablePlaceholder: 'Buscar por evento o campaña ...',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    campaignDate: 'Fecha de la campaña',
    active: 'Activa',
    finalized: 'Finalizada',
    in_review: 'En revisión',
    paused: 'Pausada',
    draft: 'En borrador',
    refused: 'Rechazada',
    stopped: 'Detenida',
    adName: 'Nombre del Anuncio',
    adState: 'Estado del anuncio',
    adCreate: 'Crear nuevo anuncio',
    approved: 'Aprobado',
    error: 'Error',
    investmentTooltip: 'Cantidad de dinero invertida para la campaña',
    incomeTooltip: 'Cantidad de dinero recibida a través de tu campaña',
    soldTicketsTooltip: 'Total de entradas compradas a través de tu campaña',
    roasTooltip:
      'Retorno de la Inversión en Publicidad. Cantidad de dinero obtenido por cada peso invertido',
    draftTooltip: 'Te falta terminar tu campaña',
    activateTooltip: 'Iniciar o pausar {{type}}',
    stopTooltip: 'Detener {{type}}',
    sortEndDate: 'Próximo a finalizar',
    sortStartDate: 'Próximo a iniciar',
    sortActive: 'En curso',
    sortCreateDate: 'Fecha de creación',
    sortAscBudget: 'Menor presupuesto',
    sortDescBudget: 'Mayor presupuesto',
    showAll: 'Ver todas',
    default: 'Por defecto',
    invalidDate: 'El formato de la fecha debe ser dd/mm/aaaa',
    invalidEndDate:
      'La fecha de finalización debe ser posterior a la de inicio',
    noEventOptions: 'Evento no encontrado',
    noBillboardOptions: 'Cartelera no encontrada',
    reviewAd:
      'Tu anuncio <strong>{{ad}}</strong> necesita revisarse para ser aprobado.',
    reviewCampaign:
      'Tu campaña <strong>{{campaign}}</strong> necesita revisarse para ser aprobada.',
    review: 'Revisar',
    CAMPAIGN_NO_ACTIVE_ADS: 'No hay anuncios activos en esta campaña',
    activateSuccess: 'Campaña activada con éxito',
    pauseSuccess: 'Campaña pausada con éxito',
    activateAdSuccess: 'Anuncio activado con éxito',
    pauseAdSuccess: 'Anuncio pausado con éxito',
    next: 'Siguiente',
    previous: 'Atrás',
    itemsPerPage: 'Items por página',
    noResults: 'No se encontraron campañas',
    create: {
      where: '¿Donde vamos a crear la campaña?',
      eventDateWarning: 'Ten presente la fecha de tu {{owner}} - {{date}}',
      billboards: 'Crea campañas para tus carteleras para impulsar sus ventas',
      events: 'Crea campañas para tus eventos y aumenta las ventas',
      title: 'Crea tu campaña',
      edit: 'Edita tu campaña',
      description:
        'Aumenta tus ventas creando anuncios liderados por nuestro equipo de marketing en Boletia.',
      name: 'Nombre de la campaña',
      assignBudget: 'Asignar presupuesto',
      min: 'Mínimo',
      recommended: 'Recomendado',
      max: 'Máximo',
      exceedsBalance:
        'Tu presupuesto asignado excede el saldo a favor que tienes actualmente, te invitamos a realizar un <strong>Aumento de tu saldo aquí</strong>',
      saveDraft: 'Guardar como borrador',
      continueAd: 'Guardar y crear anuncio nuevo',
      addAd: 'Cargar anuncio',
      adDescription:
        'Carga el cartel de tu anuncio y cuéntale a los asistente qué verán.',
      guide: 'Usa la guía para crear tus anuncios',
      downloadGuide: 'Descargar guía',
      square: 'Cuadrado',
      horizontal: 'Horizontal',
      adRule1:
        'Los formatos aceptados son .jpg, .png y .gif no deben superar un peso de más de {{maxSize}}',
      adRule2: 'Los formatos aceptados son de {{width}}X{{height}} px',
      adRule3:
        'Los diseños pasarán por un proceso de curaduría para revisar su contenido',
      eventDescription: 'Describe lo más importante de tu evento',
      maxDescription: 'Máximo 70 caracteres',
      terms:
        'Acepto los <terms>Términos y Condiciones</terms> y las <privacy>Políticas de privacidad</privacy> de Boletia',
      save: 'Guardar y enviar',
      goBack: 'Volver',
      budget: 'Presupuesto',
      drag: 'Arrastra una imagen de {{width}}X{{height}} pixeles hasta aquí',
      upload: 'Sube un archivo',
      actionCall: 'Llamada a la acción',
      boosted: 'Impulsado por',
      replace: 'Reemplazar imagen',
      remove: 'Quitar imagen',
      budgetError: 'Hubo un error al calcular el presupuesto',
      budgerRequired: 'El presupuesto es requerido',
      budgetMax: 'El presupuesto máximo es de {{max}}',
      budgetMin: 'El presupuesto mínimo es de {{min}}',
      requiredDateFormat:
        'La fecha y hora son incorrectas, por favor ingresa la información con el siguiente formato: dd/mm/aaaa hh:hh (a|p)m',
      CAMPAIGN_BUDGET_TRANSACTION_NOT_ENOUGH_BALANCE:
        'No hay saldo suficiente para crear el anuncio',
      CAMPAIGN_START_DATE:
        'La fecha de inicio de la campaña no puede ser menor a la fecha actual',
      CAMPAIGN_START_DATE_AFTER_EVENT_OR_BILLBOARD_START_DATE:
        'La fecha de inicio de la campaña no puede ser posterior a la fecha de inicio del evento o cartelera ({{ownerStartDate}})',
      CAMPAIGN_END_DATE_MORE_THAN_EVENT_OR_BILLBOARD_END_DATE:
        'La fecha de finalización de la campaña no puede ser mayor a la fecha de finalización del evento o cartelera ({{ownerEndDate}})',
      failedToFetch: 'Hubo un error con el servicio',
      adError: 'Hubo un error al crear el anuncio',
      adEditError: 'Hubo un error al actualizar el anuncio',
      CAMPAIGN_QUERY_PROCESS: 'Hubo un error con el servicio',
      CAMPAIGN_IMG_NAME_EMPTY: 'Error en la carga de la imagen',
      campaignError: 'Hubo un error al crear la campaña',
      campaignSuccess: 'Campaña creada con éxito',
      draftSuccess: 'Campaña guardada como borrador',
      draftError: 'Hubo un error al guardar la campaña como borrador',
      shortBalance: {
        title: 'No cuentas con un saldo suficiente para ejecutar esta campaña',
        description:
          'Te invitamos a recargar tu cuenta para poder ejecutar la campaña y lograr aumentar la venta de tus boletos.',
        addBalance: 'Realizar recarga',
      },
      adSuccess: {
        title: 'Tarea exitosa',
        description:
          'Verificamos todos los anuncios y campañas, para asegurarnos que cumplan los requisitos antes de publicarlos. Esto puede tomar de 24 a 48 horas y te notificaremos vía mail y dentro del admin.',
        newAd: 'Crear otro anuncio',
      },
      editSuccess: 'Campaña actualizada con éxito',
      editError: 'Hubo un error al actualizar la campaña',
      editAd: 'Editar anuncio',
      editAdLoading: 'Actualizando anuncio...',
      createAdLoading: 'Creando anuncio...',
      createCampaignLoading: 'Creando campaña...',
      editCampaignLoading: 'Actualizando campaña...',
      guideFile: 'Guía_para_crear_tus_anuncios',
      downloadGuideError: 'Hubo un error al descargar la guía',
    },
    budgetDialog: {
      title: 'Elige el presupuesto adecuado según tus objetivos',
      min: '<color>Presupuesto mínimo:</color> <bold>Ideal para probar</bold> la plataforma con visibilidad básica. Menor frecuencia y ubicaciones menos destacadas, lo que podría resultar en menos clics.',
      recommended:
        '<color>Presupuesto recomendado:</color> Buen <bold>equilibrio entre visibilidad y coste.</bold> Frecuencia moderada en ubicaciones destacadas, alcanzando una audiencia más amplia con interacciones razonables.',
      max: '<color>Presupuesto máximo:</color> Máxima <bold>exposición y efectividad.</bold> Tu anuncio aparecerá en posiciones privilegiadas y con alta frecuencia, alcanzando una audiencia muy amplia y aumentando la probabilidad de recibir clics.',
    },
    addBalance: {
      addBalance: 'Agregar saldo',
      title: 'Ingresar información de pago',
      description:
        'Aumenta tu saldo para realizar campañas y lograr aumentar tus ventas',
      amount: 'Escribe aquí cuanto deseas recargar',
      cardPayment: 'Pago con tarjeta',
      card: 'Número de tarjeta',
      expiration: 'Expiración MM/AA',
      cvv: 'CVV',
      cardHolder: 'Titular de la tarjeta',
      cardHolderRequired: 'El titular de la tarjeta es requerido',
      submit: 'Recargar ahora',
      invoice:
        'Para solicitar factura deberás contactar a tu agente de cuenta de Boletia.',
      seeTransactions: 'Ver ultimas transacciones',
      amountRequired: 'El monto es requerido',
      minRequired: 'El monto mínimo es de {{min}} MXN',
      maxRequired: 'El monto máximo es de {{max}} MXN',
      transactions: 'Resumen de transacciones',
      download: 'Descargar CSV',
      transactionsAmount: 'Monto de la recarga',
      transactionsDate: 'Fecha de la recarga',
      confirmAmount: 'Confirmar monto',
      editAmount: 'Editar monto',
      card_declined:
        'La tarjeta fue rechazada, por favor intenta con otra tarjeta',
      expired_card:
        'La tarjeta ha expirado, por favor intenta con otra tarjeta',
      incorrect_cvc: 'El código de seguridad de la tarjeta es incorrecto',
      processing_error:
        'Hubo un error con el servicio, por favor intenta mas tarde o con otra tarjeta',
      payment_intent_unexpected_state:
        'Hubo un error con el servicio, por favor intenta mas tarde',
    },
    validations: {
      event: 'El evento es requerido',
      billboard: 'La cartelera es requerida',
      campaignName: 'El nombre de la campaña es requerido',
      start: 'La fecha de inicio es requerida',
      end: 'La fecha de finalización es requerida',
      adName: 'El nombre del anuncio es requerido',
      actionCall: 'La llamada a la acción es requerida',
      description: 'La descripción es requerida',
      maxDescription: 'Máximo 70 caracteres',
    },
    superAdmin: {
      organizer: 'Organizador',
      activeCampaigns: 'Campañas activas',
      selectOrganizer: 'Buscar un organizador...',
      manageOrganizer: 'Administración de organizadores',
      noOrganizers: 'Organizador no encontrado',
      organizerData: 'Datos del organizador',
      config: 'Configuraciones',
      activeEvents: 'Eventos activos',
      whitelisted: 'En lista blanca',
      gmv: 'Activar uso de <strong>GMV</strong>',
      balance: 'Saldo',
      campaignsReview: 'Revisión de campañas',
      campaignReview: 'Revisión de campaña',
      rejectCampaign: 'Rechazar campaña',
      reject: 'Rechazar',
      ads: 'Anuncios',
      ad: 'Anuncio',
      eventDate: 'Fecha del evento',
      assignedBudget: 'Presupuesto asignado',
      description: 'Descripción',
      approveAd: 'Aprobar anuncio',
      rejectReason: 'Motivos de la devolución de la campaña',
      reasonPlaceholder: 'Descripción del motivo',
      rejectingCampaign: 'Rechazando campaña...',
      rejectingAd: 'Rechazando anuncio...',
      adRejected: 'Anuncio rechazado',
      campaignRejected: 'Campaña rechazada',
      errorRejectingCampaign: 'Hubo un error al rechazar la campaña',
      errorRejectingAd: 'Hubo un error al rechazar el anuncio',
      errorStoppingCampaign: 'Hubo un error al detener la campaña',
      errorStoppingAd: 'Hubo un error al detener el anuncio',
      stoppingCampaign: 'Deteniendo campaña...',
      stoppingAd: 'Deteniendo anuncio...',
      campaignStopped: 'Campaña detenida',
      adStopped: 'Anuncio detenido',
      campaignApproved: 'Campaña aprobada',
      errorApprovingCampaign: 'Hubo un error al aprobar la campaña',
      send: 'Enviar',
      rejectAdReason: 'Motivos de la devolución del anuncio',
      stopReason: 'Motivos de la detención',
      stopCampaignConfirm:
        'Le notificaremos al organizador {{user}} vía mail y en su admin sobre la detención de la campaña',
      stopAdConfirm:
        'Le notificaremos al organizador {{user}} vía mail y en su admin sobre la detención del anuncio',
      confirmApprove: '¿Desea aprobar el anuncio?',
      notifyConfirm:
        'Le notificaremos al organizador {{user}} vía mail y en su admin sobre la aprobación del anuncio.',
      continueConfirm: 'Sí, aprobar y enviar',
      errorApprovingAd: 'Hubo un error al aprobar el anuncio',
      adApproved: 'Anuncio aprobado',
      approvingAd: 'Aprobando anuncio...',
      rechargeValue: 'Valor a recargar',
      rechargePlaceholder: 'Descripción',
      rechargeBoletia: 'Recarga Boletia',
      currentBalance: 'Saldo actual',
      total: 'Total',
      recharge: 'Recargar Ahora',
      rechargeSuccess: 'Recarga exitosa',
      rechargeError: 'Hubo un error al recargar',
      rechargeLoading: 'Recargando...',
      amountRequired: 'El monto es requerido',
      descriptionRequired: 'La descripción es requerida',
      emptyOrganizers: 'No hay organizadores para mostrar',
      loadingOrganizer: 'Buscando organizador...',
      mktBudget: 'Presupuesto Mkt',
    },
  },
};
